<template>
  <b-modal
    centered
    modal-class="modal-primary"
    size="lg"
    :visible="visible"
    :title="$t('Absences.ViewShiftImpactTitle')"
    :ok-title="$t('Global.Ok')"
    ok-only
    @hide="$emit('close')"
    @ok="$emit('close')"
  >
    <p v-if="impactOnShifts.length === 0">
      {{ $t('Absences.CheckModalInfoTextNone', employee) }}.
    </p>
    <p v-else>{{ $t('Absences.CheckModalInfoText', employee) }}:</p>
    <div
      v-for="impactOnShift in impactOnShifts"
      :key="impactOnShift.id"
      class="mb-2"
    >
      <div class="d-flex algin-items-center">
        <Status
          :key="impactOnShift.id"
          :status="impactOnShift.staffScheduling.status"
        />
        <b>
          {{ formatDate(impactOnShift.staffScheduling.date) }} -
          {{ getLayer(impactOnShift.staffScheduling.layer).area }} -
          {{
            getLayer(impactOnShift.staffScheduling.layer).configuration.layers[
              impactOnShift.staffScheduling.layerIndex
            ].name
          }}
        </b>
      </div>
      <div class="ml-2 d-flex align-items-center gap-2">
        <feather-icon
          icon="ArrowRightIcon"
          size="16"
          class="shift-impact-text-icon"
        />
        <div>{{ getImpactOnShiftText(impactOnShift.staffScheduling) }}</div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment'

import Status from '@/views/loggedin/staff-scheduling/components/content/status.vue'
import StaffSchedulingAttendance from '@/views/loggedin/manage-shifts/enums'
import { StaffSchedulingStatus } from '@/views/loggedin/staff-scheduling/enums'

export default {
  name: 'ImpactOnShiftModal',

  components: {
    Status,
  },

  props: {
    absence: {
      type: Object,
      default: () => null,
    },
    layers: {
      type: Array,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    impactOnShifts: [],
  }),

  computed: {
    visible() {
      return !!this.absence
    },

    getLayer() {
      const dictionary = this.layers.reduce((acc, layer) => {
        acc[layer.id] = layer
        return acc
      }, {})

      return id => dictionary[id]
    },

    employee() {
      if (!this.absence) return null

      return this.employees.find(
        employee => employee.id === this.absence.employee,
      )
    },
  },

  watch: {
    absence: {
      immediate: true,
      async handler(value) {
        if (!value) return

        await this.fetchImpactOnShift()
      },
    },
  },

  methods: {
    getImpactOnShiftText(shift) {
      switch (shift.status) {
        case StaffSchedulingStatus.open:
        case StaffSchedulingStatus.transmitted:
          return this.$t(
            'Absences.ImpactOnShiftDisplay.PlannedShiftGetsDeleted',
          )

        case StaffSchedulingStatus.rejected:
        case StaffSchedulingStatus.canceled:
        case StaffSchedulingStatus.cancellationNoted:
          return this.$t(
            'Absences.ImpactOnShiftDisplay.CanceledShiftsGetDeleted',
          )

        case StaffSchedulingStatus.approved:
          if (shift.attendance === StaffSchedulingAttendance.available) {
            return this.$t(
              'Absences.ImpactOnShiftDisplay.ApprovedShiftGetsMarkedAsSick',
            )
          }
          return this.$t(
            'Absences.ImpactOnShiftDisplay.ApprovedShiftGetsRemoved',
          )

        default:
          return this.$t('Absences.ImpactOnShiftDisplay.Unknown')
      }
    },

    async fetchImpactOnShift() {
      try {
        const { data } = await this.$axios.get(
          `/absences/impact-on-shift/${this.absence.id}`,
        )

        this.impactOnShifts = data
      } catch (error) {
        this.$alert.error()
        console.error(error)
      }
    },

    formatDate(date) {
      return moment(date).format('DD.MM.YYYY')
    },
  },
}
</script>

<style lang="scss" scoped>
.gap-2 {
  gap: 0.5rem;
}
</style>
