const dialog = async (
  { $bvModal, $i18n, $axios, $alert, $createElement },
  { id, subject, individual, endpoint, callback },
) => {
  try {
    const messageVNode = $createElement('div', {
      class: ['p-1'],
      domProps: {
        innerHTML: $i18n.t('Global.CancelQuestion', { individual, subject }),
        style: 'font-size: 16px',
      },
    })

    const choice = await $bvModal.msgBoxConfirm([messageVNode], {
      title: $i18n.t('Global.CancelSubject', { subject }),
      size: 'sm',
      okVariant: 'primary',
      cancelVariant: 'outline-secondary',
      okTitle: $i18n.t('Global.Cancel'),
      cancelTitle: $i18n.t('Global.Retreat'),
      hideHeaderClose: false,
      centered: true,
      'body-class': 'p-2',
    })

    if (!choice) return Promise.resolve(id)
  } catch (error) {
    return Promise.reject(error)
  }

  try {
    await $axios.patch(endpoint)
    $alert.cancel(individual)

    if (callback) callback()

    return Promise.resolve(id)
  } catch (error) {
    $alert.error()
    return Promise.reject(error)
  }
}

export default dialog
