<template>
  <feather-icon
    v-if="status"
    v-b-tooltip.hover.top="text"
    class="status-icon"
    :icon="icon"
    :style="{ color }"
  />
  <span v-else class="status-icon" />
</template>

<script>
import { StaffSchedulingStatus } from '../../enums'

export default {
  props: {
    status: {
      type: String,
      default: () => null,
    },
  },

  computed: {
    text() {
      return this.$t(`StaffScheduling.Status.${this.status}`)
    },
    color() {
      switch (this.status) {
        case StaffSchedulingStatus.open:
          return 'var(--secondary)'
        case StaffSchedulingStatus.transmitted:
          return 'var(--warning)'
        case StaffSchedulingStatus.approved:
          return 'var(--success)'
        case StaffSchedulingStatus.rejected:
          return 'var(--danger)'
        case StaffSchedulingStatus.canceled:
          return 'var(--danger)'
        case StaffSchedulingStatus.cancellationNoted:
          return 'var(--purple)'
        default:
          return 'inherit'
      }
    },
    icon() {
      switch (this.status) {
        case StaffSchedulingStatus.open:
          return 'HelpCircleIcon'
        case StaffSchedulingStatus.transmitted:
          return 'FastForwardIcon'
        case StaffSchedulingStatus.approved:
          return 'UserCheckIcon'
        case StaffSchedulingStatus.rejected:
          return 'UserXIcon'
        case StaffSchedulingStatus.canceled:
          return 'XIcon'
        case StaffSchedulingStatus.cancellationNoted:
          return 'SlashIcon'
        default:
          return null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.status-icon {
  align-self: center;
  width: 14px;
  height: 14px;
  margin-right: 5px;
}
</style>
