<template>
  <b-card>
    <h3 class="mb-1 font-weight-bolder">
      {{ $t('Global.Filters') }}
    </h3>
    <div class="row justify-content-between">
      <b-col lg="3" md="3" sm="12">
        <label for="employee-filter">
          {{ $t('Absences.Employee') }}
        </label>
        <v-select
          id="employee-filter"
          :value="selectEmployee"
          :options="employees"
          :placeholder="
            $t('Global.Select', {
              subject: $t('Absences.Employee'),
            })
          "
          label="id"
          :filter-by="employeeFilterBy"
          @input="handleSelectEmployee($event)"
        >
          <template #option="{ firstname, lastname, employeeNumber }">
            <span> {{ employeeNumber }} - {{ firstname }} {{ lastname }} </span>
          </template>
          <template #selected-option="{ firstname, lastname }">
            <span>{{ firstname }} {{ lastname }}</span>
          </template>
        </v-select>
      </b-col>
      <b-col lg="2" md="2" sm="12">
        <label for="filter-type">
          {{ $t('Absences.Type') }}
        </label>
        <v-select
          id="filter-type"
          v-model="filter.type"
          :options="Object.values(AbsenceType)"
          :placeholder="
            $t('Global.Select', {
              subject: $t('Absences.Type'),
            })
          "
          :filter-by="typeFilterBy"
          label="type"
        >
          <template #option="{ type }">
            <span>{{ $t(`Absences.AbsenceType.${type}`) }}</span>
          </template>
          <template #selected-option="{ type }">
            <span>{{ $t(`Absences.AbsenceType.${type}`) }}</span>
          </template>
        </v-select>
      </b-col>

      <b-col lg="2" md="2" sm="12">
        <label class="d-block" for="filter-start-date`">
          {{ $t('Absences.StartDate') }}
        </label>
        <b-input-group class="d-flex">
          <b-form-input
            id="filter-start-date"
            v-model="startDateText"
            placeholder="DD.MM.JJJJ"
            autocomplete="off"
            :state="startDateInvalid ? false : null"
          />
          <b-input-group-append>
            <b-form-datepicker
              v-model="filter.startDate"
              v-bind="$t('Bootstrap.DatePicker')"
              button-only
              locale="de-DE"
              :start-weekday="1"
              :max="filter.endDate"
              :button-variant="
                startDateInvalid ? 'outline-danger' : 'outline-primary'
              "
              class="timepicker-height"
              @input="changeDate('startDate', $event)"
            />
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col lg="2" md="2" sm="12">
        <label class="d-block" for="filter-start-date">
          {{ $t('Absences.EndDate') }}
        </label>
        <b-input-group class="d-flex">
          <b-form-input
            id="filter-end-date"
            v-model="endDateText"
            placeholder="DD.MM.JJJJ"
            autocomplete="off"
            :state="endDateInvalid ? false : null"
          />
          <b-input-group-append>
            <b-form-datepicker
              v-model="filter.endDate"
              v-bind="$t('Bootstrap.DatePicker')"
              button-only
              locale="de-DE"
              :start-weekday="1"
              :min="filter.startDate"
              :button-variant="
                endDateInvalid ? 'outline-danger' : 'outline-primary'
              "
              class="timepicker-height"
              @input="changeDate('endDate', $event)"
            />
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col lg="2" md="2" sm="12">
        <label for="filter-status">
          {{ $t('Absences.Status') }}
        </label>
        <v-select
          id="filter-status"
          v-model="filter.status"
          :options="Object.values(AbsenceStatus)"
          :placeholder="
            $t('Global.Select', {
              subject: $t('Absences.Status'),
            })
          "
          :filter-by="statusFilterBy"
          label="status"
        >
          <template #option="{ status }">
            <span>{{ $t(`Absences.AbsenceStatus.${status}`) }}</span>
          </template>
          <template #selected-option="{ status }">
            <span>{{ $t(`Absences.AbsenceStatus.${status}`) }}</span>
          </template>
        </v-select>
      </b-col>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BCol,
  BFormInput,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment'

import { dateRegex } from '@/utility/regex'

import { AbsenceType, AbsenceStatus } from '../utility/constants'

export default {
  name: 'AbsencesFilter',

  components: {
    BCard,
    BCol,
    BFormInput,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
  },

  props: {
    filter: {
      type: Object,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    AbsenceType,
    AbsenceStatus,

    startDateText: null,
    endDateText: null,
  }),

  computed: {
    selectEmployee() {
      if (this.filter.employee === null) return null
      return this.employees.find(x => x.id === this.filter.employee)
    },

    startDateInvalid() {
      if (this.startDateText === null || this.startDateText === '') return false
      return !this.isValidGermanDate(this.startDateText)
    },

    endDateInvalid() {
      if (this.endDateText === null || this.endDateText === '') return false
      return !this.isValidGermanDate(this.endDateText)
    },
  },

  watch: {
    startDateText(input) {
      if (input === null || input === '') {
        this.filter.startDate = null
        return
      }

      if (this.isValidGermanDate(input)) {
        this.filter.startDate = moment(input, 'DD.MM.YYYY').toDate()
      }
    },

    endDateText(input) {
      if (input === null || input === '') {
        this.filter.endDate = null
        return
      }

      if (this.isValidGermanDate(input)) {
        this.filter.endDate = moment(input, 'DD.MM.YYYY').toDate()
      }
    },
  },

  methods: {
    isValidGermanDate(dateString) {
      return dateRegex.test(dateString)
    },

    handleSelectEmployee(employee) {
      if (!employee) {
        this.filter.employee = null
        return
      }
      this.filter.employee = employee.id
    },

    changeDate(key, event) {
      const dateString = moment(event, 'YYYY-MM-DD').format('DD.MM.YYYY')

      if (key === 'startDate') {
        this.startDateText = dateString
      }

      if (key === 'endDate') {
        this.endDateText = dateString
      }
    },

    employeeFilterBy(option, _, search) {
      const displayString = `${option.employeeNumber} - ${option.firstname} ${option.lastname}`

      return (
        displayString.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >
        -1
      )
    },

    statusFilterBy(option, label, search) {
      const status = this.$t(`Absences.AbsenceStatus.${label}`)
      return (
        (status || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >
        -1
      )
    },

    typeFilterBy(option, label, search) {
      const type = this.$t(`Absences.AbsenceType.${label}`)
      return (
        (type || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >
        -1
      )
    },
  },
}
</script>

<style lang="scss">
.timepicker-height {
  > button.btn {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    &[disabled='disabled'] {
      background-color: #efefef !important;
      border-color: #d8d6de !important;
      color: #6e6b7b !important;
    }
  }
}
</style>
