<template>
  <b-overlay :show="isLoading">
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="ml-2 d-flex align-items-center"
      variant="primary"
      @click="openSideBar"
    >
      <feather-icon class="mr-1" icon="DatabaseIcon" size="21" />
      {{ $tc('Global.New') }} {{ $tc('Absences.Absence') }}
    </b-button>
    <Form
      ref="form"
      :absence="absence"
      :employees="employees"
      :can="$can($acl.action.Create, $acl.subjects.Absences)"
      @submit="check"
      @reset="reset"
      @send-notification="sendNotification = $event"
    />

    <CheckModal
      ref="check-modal"
      :absence="absence"
      :layers="layers"
      :employees="employees"
      @confirm="submit"
    />
  </b-overlay>
</template>

<script>
import { BButton, BOverlay } from 'bootstrap-vue'
import moment from 'moment'

import { absenceDefaultFactory } from '../utility/factory'

import CheckModal from './check-modal.vue'
import Form from './form.vue'

export default {
  components: {
    BButton,
    BOverlay,
    Form,
    CheckModal,
  },

  props: {
    employees: {
      type: Array,
      required: true,
    },
    layers: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    absence: absenceDefaultFactory(),
    isLoading: false,

    sendNotification: true,
  }),

  methods: {
    reset() {
      this.absence = absenceDefaultFactory()
    },

    openSideBar() {
      this.$refs.form.sidebarVisible = true
    },

    async checkExistingAbsence() {
      const startDate = moment(this.absence.startDate, 'DD.MM.YYYY').format(
        'YYYY-MM-DD',
      )
      const endDate = moment(this.absence.endDate, 'DD.MM.YYYY').format(
        'YYYY-MM-DD',
      )

      const { data } = await this.$axios.get(
        `absences/has-existing-absence?employee=${this.absence.employee.id}&start-date=${startDate}&end-date=${endDate}`,
      )

      if (data.hasExistingAbsence === false) {
        return null
      }

      return data.absence
    },

    async check() {
      this.isLoading = true

      try {
        const existingAbsence = await this.checkExistingAbsence()

        if (existingAbsence !== null) {
          this.$alert.invalid(
            this.$t('Absences.AlreadyExists', {
              startDate: moment(existingAbsence.startDate).format('DD.MM.YYYY'),
              endDate: moment(existingAbsence.endDate).format('DD.MM.YYYY'),
            }),
          )
          return
        }

        const startDate = moment(this.absence.startDate, 'DD.MM.YYYY').format(
          'YYYY-MM-DD',
        )
        const endDate = moment(this.absence.endDate, 'DD.MM.YYYY').format(
          'YYYY-MM-DD',
        )

        const { data } = await this.$axios.get(
          `staff-scheduling?employee=${this.absence.employee.id}&start-date=${startDate}&end-date=${endDate}`,
        )

        if (data.length === 0) {
          await this.submit()
          return
        }

        this.$refs['check-modal'].init(data)
      } catch (error) {
        console.error(error)
        this.$alert.error()
      } finally {
        this.isLoading = false
      }
    },

    async submit() {
      this.isLoading = true
      try {
        const existingAbsence = await this.checkExistingAbsence()

        if (existingAbsence !== null) {
          this.$alert.invalid(
            this.$t('Absences.AlreadyExists', {
              startDate: moment(existingAbsence.startDate).format('DD.MM.YYYY'),
              endDate: moment(existingAbsence.endDate).format('DD.MM.YYYY'),
            }),
          )
          return
        }

        const payload = {
          ...this.absence,
          employee: this.absence.employee.id,
          startDate: moment(this.absence.startDate, 'DD.MM.YYYY').format(
            'YYYY-MM-DD',
          ),
          endDate: moment(this.absence.endDate, 'DD.MM.YYYY').format(
            'YYYY-MM-DD',
          ),
        }

        const { data } = await this.$axios.post(
          `absences?send-notification=${this.sendNotification}`,
          payload,
        )

        this.$alert.create(
          undefined,
          this.$t('Absences.Created', this.absence.employee),
        )
        this.$emit('create', data)

        this.$refs.form.dirty = false
        this.$refs.form.sidebarVisible = false
      } catch (error) {
        console.error(error)
        this.$alert.error()
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
