<template>
  <b-overlay :show="isLoading">
    <Form
      ref="form"
      :absence="update"
      :employees="employees"
      :can="$can($acl.action.Update, $acl.subjects.Absences)"
      is-update
      @submit="check"
      @reset="reset"
      @send-notification="sendNotification = $event"
    />

    <CheckModal
      v-if="update !== null"
      ref="check-modal"
      :absence="update"
      :layers="layers"
      :employees="employees"
      @confirm="submit"
    />
  </b-overlay>
</template>

<script>
import { BOverlay } from 'bootstrap-vue'
import moment from 'moment'

import CheckModal from './check-modal.vue'
import Form from './form.vue'

export default {
  components: {
    BOverlay,
    Form,
    CheckModal,
  },

  props: {
    employees: {
      type: Array,
      required: true,
    },
    absence: {
      type: Object,
      default: null,
    },
    layers: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    update: null,

    isLoading: false,
    sendNotification: true,
  }),

  computed: {
    didDateRangeChange() {
      if (this.update === null) {
        return false
      }

      return (
        this.update.startDate !== this.absence.startDate ||
        this.update.endDate !== this.absence.endDate
      )
    },
  },

  watch: {
    absence(incomingAbsence) {
      if (incomingAbsence === null) {
        this.reset()
        return
      }

      this.update = this.formatUpdate(incomingAbsence)

      this.$nextTick(() => {
        this.$refs.form.sidebarVisible = true
      })
    },
  },

  methods: {
    openSideBar() {
      this.$refs.form.sidebarVisible = true
    },

    reset() {
      this.$emit('reset')

      // wait for animation to end
      setTimeout(() => {
        this.update = null
      }, 350)
    },

    formatUpdate(absence) {
      const update = JSON.parse(JSON.stringify(absence))

      update.startDate = moment(absence.startDate).format('DD.MM.YYYY')
      update.endDate = moment(absence.endDate).format('DD.MM.YYYY')

      update.employee = this.employees.find(
        employee => employee.id === absence.employee,
      )

      return update
    },

    async submit() {
      try {
        const payload = {
          ...this.update,
          employee: this.update.employee.id,
          startDate: moment(this.update.startDate, 'DD.MM.YYYY').format(
            'YYYY-MM-DD',
          ),
          endDate: moment(this.update.endDate, 'DD.MM.YYYY').format(
            'YYYY-MM-DD',
          ),
        }

        const { data } = await this.$axios.patch(
          `absences/${this.absence.id}?send-notification=${this.sendNotification}`,
          payload,
        )

        this.$alert.update(
          undefined,
          this.$t('Absences.Updated', this.update.employee),
        )
        this.$emit('update', data)

        this.$refs.form.dirty = false
        this.$refs.form.sidebarVisible = false
        this.reset()
      } catch (error) {
        console.error(error)
        this.$alert.error()
      }
    },

    async checkExistingAbsence() {
      if (this.didDateRangeChange === false) return null

      const startDate = moment(this.update.startDate, 'DD.MM.YYYY').format(
        'YYYY-MM-DD',
      )
      const endDate = moment(this.update.endDate, 'DD.MM.YYYY').format(
        'YYYY-MM-DD',
      )

      const { data } = await this.$axios.get(
        `absences/has-existing-absence?employee=${this.update.employee.id}&start-date=${startDate}&end-date=${endDate}&ignore-absence=${this.absence.id}`,
      )

      if (data.hasExistingAbsence === false) {
        return null
      }

      return data.absence
    },

    async check() {
      this.isLoading = true

      try {
        const existingAbsence = await this.checkExistingAbsence()

        if (existingAbsence !== null) {
          this.$alert.invalid(
            this.$t('Absences.AlreadyExists', {
              startDate: moment(existingAbsence.startDate).format('DD.MM.YYYY'),
              endDate: moment(existingAbsence.endDate).format('DD.MM.YYYY'),
            }),
          )
          return
        }

        const startDate = moment(this.update.startDate, 'DD.MM.YYYY').format(
          'YYYY-MM-DD',
        )
        const endDate = moment(this.update.endDate, 'DD.MM.YYYY').format(
          'YYYY-MM-DD',
        )

        const { data } = await this.$axios.get(
          `staff-scheduling?employee=${this.update.employee.id}&start-date=${startDate}&end-date=${endDate}&is-sick=false`,
        )

        if (data.length === 0) {
          await this.submit()
          return
        }

        this.$refs['check-modal'].init(data)
      } catch (error) {
        console.error(error)
        this.$alert.error()
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
