<template>
  <b-modal
    centered
    modal-class="modal-primary"
    size="lg"
    :visible="visible"
    :title="$t('Absences.CheckModalTitle')"
    :cancel-title="$t('Global.Retreat')"
    :ok-title="$t('Global.Ok')"
    cancel-variant="outline-secondary"
    @hide="checkContext = null"
    @cancel="checkContext = null"
    @ok="confirm"
  >
    <div v-if="checkContext">
      <p v-if="checkContext.length === 0">
        {{ $t('Absences.CheckModalInfoTextNone', absence.employee) }}.
      </p>
      <p v-else>{{ $t('Absences.CheckModalInfoText', absence.employee) }}:</p>
      <div v-for="shift in checkContext" :key="shift.id" class="mb-2">
        <div class="d-flex algin-items-center">
          <Status :status="shift.status" />
          <b>
            {{ formatDate(shift.date) }} - {{ getLayer(shift.layer).area }} -
            {{
              getLayer(shift.layer).configuration.layers[shift.layerIndex].name
            }}
          </b>
        </div>
        <div class="ml-2 d-flex align-items-center gap-2">
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="shift-impact-text-icon"
          />
          <div>{{ getImpactOnShiftText(shift) }}</div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment'

import Status from '@/views/loggedin/staff-scheduling/components/content/status.vue'
import { StaffSchedulingStatus } from '@/views/loggedin/staff-scheduling/enums'
import StaffSchedulingAttendance from '@/views/loggedin/manage-shifts/enums'

export default {
  name: 'CheckModal',

  components: {
    Status,
  },

  props: {
    absence: {
      type: Object,
      required: true,
    },
    layers: {
      type: Array,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    checkContext: null,
  }),

  computed: {
    visible() {
      return !!this.checkContext
    },

    getLayer() {
      const dictionary = this.layers.reduce((acc, layer) => {
        acc[layer.id] = layer
        return acc
      }, {})

      return id => dictionary[id]
    },
  },

  methods: {
    getImpactOnShiftText(shift) {
      switch (shift.status) {
        case StaffSchedulingStatus.open:
        case StaffSchedulingStatus.transmitted:
          return this.$t('Absences.ImpactOnShift.PlannedShiftGetsDeleted')

        case StaffSchedulingStatus.rejected:
        case StaffSchedulingStatus.canceled:
        case StaffSchedulingStatus.cancellationNoted:
          return this.$t('Absences.ImpactOnShift.CanceledShiftsGetDeleted')

        case StaffSchedulingStatus.approved:
          if (shift.attendance === StaffSchedulingAttendance.available) {
            return this.$t(
              'Absences.ImpactOnShift.ApprovedShiftGetsMarkedAsSick',
            )
          }
          return this.$t('Absences.ImpactOnShift.ApprovedShiftGetsRemoved')

        default:
          return this.$t('Absences.ImpactOnShift.Unknown')
      }
    },

    init(check) {
      this.checkContext = check
    },

    confirm() {
      this.$emit('confirm')
    },

    formatDate(date) {
      return moment(date).format('DD.MM.YYYY')
    },
  },
}
</script>

<style lang="scss" scoped>
.gap-2 {
  gap: 0.5rem;
}
</style>
