var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.absences,"search-options":{
    enabled: true,
    externalQuery: _vm.searchTerm,
  },"pagination-options":{
    enabled: true,
    perPage: _vm.pageLength,
  }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',{staticClass:"action"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t("Absences.ViewShiftImpact")),expression:"$t(`Absences.ViewShiftImpact`)",modifiers:{"hover":true,"top":true}}]},[_c('feather-icon',{staticClass:"hover-orange",attrs:{"icon":"UsersIcon","size":"16"},on:{"click":function($event){return _vm.$emit('view-impact', props.row)}}})],1),(
          _vm.$can(_vm.$acl.action.Update, _vm.$acl.subjects.Absences) &&
          _vm.AbsenceStatus.canceled !== props.row.status
        )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
          _vm.$t(
            ("Global." + (_vm.$can(_vm.$acl.action.Update, _vm.$acl.subjects.Absences)
                ? 'EditSubject'
                : 'ViewSubject')),
            { subject: _vm.$t('Absences.Absence') }
          )
        ),expression:"\n          $t(\n            `Global.${\n              $can($acl.action.Update, $acl.subjects.Absences)\n                ? 'EditSubject'\n                : 'ViewSubject'\n            }`,\n            { subject: $t('Absences.Absence') },\n          )\n        ",modifiers:{"hover":true,"top":true}}]},[_c('feather-icon',{staticClass:"hover-green",attrs:{"icon":_vm.$can(_vm.$acl.action.Update, _vm.$acl.subjects.Absences)
              ? 'Edit2Icon'
              : 'EyeIcon',"size":"16"},on:{"click":function($event){return _vm.$emit('request-update', props.row)}}})],1):_vm._e(),(
          _vm.$can(_vm.$acl.action.Delete, _vm.$acl.subjects.Absences) &&
          _vm.AbsenceStatus.canceled === props.row.status
        )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
          _vm.$t('Global.DeleteSubject', {
            subject: _vm.$t('Absences.Absence'),
          })
        ),expression:"\n          $t('Global.DeleteSubject', {\n            subject: $t('Absences.Absence'),\n          })\n        ",modifiers:{"hover":true,"top":true}}]},[_c('feather-icon',{staticClass:"hover-red",attrs:{"icon":"TrashIcon","size":"16"},on:{"click":function($event){return _vm.$emit('delete', props.row)}}})],1):_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
          _vm.$t('Global.CancelSubject', {
            subject: _vm.$t('Absences.Absence'),
          })
        ),expression:"\n          $t('Global.CancelSubject', {\n            subject: $t('Absences.Absence'),\n          })\n        ",modifiers:{"hover":true,"top":true}}]},[_c('feather-icon',{staticClass:"hover-red",attrs:{"icon":"XCircleIcon","size":"16"},on:{"click":function($event){return _vm.$emit('cancel', props.row)}}})],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap px-2 pb-2"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1 flex-wrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('Global.ShowingOneOf'))+" ")]),_c('v-select',{staticClass:"mx-1",staticStyle:{"min-width":"75px"},attrs:{"clearable":false,"options":['10', '15', '30']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t('Global.EntriesShown')))])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.$t('Global.Emptystate'))+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }