<template>
  <b-sidebar
    ref="sidebar"
    shadow
    backdrop
    right
    no-close-on-backdrop
    no-close-on-esc
    no-enforce-focus
    bg-variant="white"
    width="420px"
  >
    <template #header>
      <h3 class="my-1 mx-2 font-weight-bolder">
        <span v-if="!isUpdate">{{ $tc('Global.New') }}&nbsp;</span>
        <span>{{ $tc('Absences.Absences') }}</span>
        <span v-if="isUpdate">&nbsp;{{ $t('Global.Edit') }}</span>
      </h3>
    </template>
    <validation-observer
      ref="rules"
      tag="form"
      class="px-3 mt-2"
      @submit.prevent="submit"
    >
      <section v-if="absence">
        <!-- START - Employee - START -->
        <validation-provider
          #default="{ errors }"
          :name="$t('Absences.Employee')"
          :rules="{ required: true }"
        >
          <b-form-group :label="$t('Absences.Employee')" label-for="employee">
            <v-select
              id="employee"
              v-model="absence.employee"
              :options="employees"
              :placeholder="
                $t('Global.Select', {
                  subject: $t('Absences.Employee'),
                })
              "
              :class="{
                invalid: errors.length > 0,
              }"
              :disabled="!can || isUpdate"
              label="id"
              :filter-by="employeeFilterBy"
            >
              <template #option="{ firstname, lastname, employeeNumber }">
                <span>
                  {{ employeeNumber }} - {{ firstname }} {{ lastname }}
                </span>
              </template>
              <template #selected-option="{ firstname, lastname }">
                <span>{{ firstname }} {{ lastname }}</span>
              </template>
            </v-select>
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        <!-- END - Employee - END -->

        <!-- START - Type - START -->
        <validation-provider
          #default="{ errors }"
          :name="$t('Absences.Type')"
          :rules="{ required: true }"
        >
          <b-form-group :label="$t('Absences.Type')" label-for="type">
            <v-select
              id="type"
              v-model="absence.type"
              :options="Object.values(AbsenceType)"
              :placeholder="
                $t('Global.Select', {
                  subject: $t('Absences.Type'),
                })
              "
              :class="{
                invalid: errors.length > 0,
              }"
              :disabled="!can"
              label="type"
            >
              <template #option="{ type }">
                <span>{{ $t(`Absences.AbsenceType.${type}`) }}</span>
              </template>
              <template #selected-option="{ type }">
                <span>{{ $t(`Absences.AbsenceType.${type}`) }}</span>
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <!-- END - Type - END -->

        <!-- START - StartDate - START -->
        <validation-provider
          #default="{ errors }"
          :name="$t('Absences.StartDate')"
          :rules="{ required: true, regex: dateRegex }"
          class="mb-1"
        >
          <label class="d-block" for="start-date">
            {{ $t('Absences.StartDate') }}*
          </label>
          <b-input-group class="d-flex">
            <b-form-input
              id="start-date"
              v-model="absence.startDate"
              placeholder="DD.MM.JJJJ"
              autocomplete="off"
              :disabled="!can"
              :state="errors.length > 0 ? false : null"
              @input="changeDateString('startDate', $event)"
            />
            <b-input-group-append>
              <b-form-datepicker
                v-model="startDate"
                v-bind="$t('Bootstrap.DatePicker')"
                button-only
                locale="de-DE"
                :disabled="!can"
                :start-weekday="1"
                :button-variant="
                  errors.length > 0 ? 'outline-danger' : 'outline-primary'
                "
                :max="endDate"
                class="timepicker-height"
                @input="changeDate('startDate', $event)"
              />
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger" style="height: 15px; display: block">
            {{ errors[0] }}
          </small>
        </validation-provider>
        <!-- END - StartDate - END -->

        <!-- START - EndDate - START -->
        <validation-provider
          #default="{ errors }"
          :name="$t('Absences.EndDate')"
          :rules="{ required: true, regex: dateRegex }"
          class="mb-1"
        >
          <label class="d-block" for="start-date">
            {{ $t('Absences.EndDate') }}*
          </label>
          <b-input-group class="d-flex">
            <b-form-input
              id="end-date"
              v-model="absence.endDate"
              placeholder="DD.MM.JJJJ"
              autocomplete="off"
              :disabled="!can"
              :state="errors.length > 0 ? false : null"
              @input="changeDateString('endDate', $event)"
            />
            <b-input-group-append>
              <b-form-datepicker
                v-model="endDate"
                v-bind="$t('Bootstrap.DatePicker')"
                button-only
                locale="de-DE"
                :disabled="!can"
                :start-weekday="1"
                :button-variant="
                  errors.length > 0 ? 'outline-danger' : 'outline-primary'
                "
                :min="startDate"
                class="timepicker-height"
                @input="changeDate('endDate', $event)"
              />
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger" style="height: 15px; display: block">
            {{ errors[0] }}
          </small>
        </validation-provider>
        <!-- END - EndDate - END -->

        <!-- START - Note - START -->
        <validation-provider :name="$t('Absences.Note')">
          <b-form-group :label="$t('Absences.Note')" label-for="note">
            <b-form-textarea
              id="note"
              v-model="absence.note"
              rows="5"
              no-resize
              :disabled="!can"
            />
          </b-form-group>
        </validation-provider>
        <!-- END - Note - END -->

        <b-form-group label-for="send-notification">
          <b-form-checkbox
            :label="$t('Absences.SendNotification')"
            :value="true"
            @change="$emit('send-notification', $event)"
          >
            {{ $t('Absences.SendNotification') }}
          </b-form-checkbox>
        </b-form-group>
      </section>
    </validation-observer>
    <template #footer>
      <div class="d-flex justify-content-between m-2">
        <b-button
          v-if="can"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="submit"
        >
          {{ $t('Global.Save') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          @click="sidebarVisible = false"
        >
          {{ $t('Global.Retreat') }}
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton,
  BSidebar,
  BFormInput,
  BFormGroup,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import moment from 'moment'

import { required, number } from '@validations'
import { dateRegex } from '@/utility/regex'
import { preventUnsavedChangesPopupLevel } from '@/utility/mixins/preventUnsavedChanges'

import { AbsenceType } from '../utility/constants'

export default {
  components: {
    BFormCheckbox,
    BButton,
    BSidebar,
    BFormInput,
    BFormGroup,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },

  mixins: [preventUnsavedChangesPopupLevel],

  props: {
    absence: {
      type: Object,
      default: () => null,
    },
    employees: {
      type: Array,
      required: true,
    },
    can: {
      type: Boolean,
      required: true,
    },
    isUpdate: {
      type: Boolean,
      default: () => false,
    },
  },

  data: () => ({
    required,
    number,
    dateRegex,
    AbsenceType,

    startDate: new Date(),
    endDate: new Date(),
  }),

  watch: {
    absence(incomingAbsence) {
      this.initAbsence(incomingAbsence)
    },
  },

  mounted() {
    this.initAbsence(this.absence)
  },

  methods: {
    initAbsence(absence) {
      if (!absence) {
        return
      }

      this.startDate = moment(absence.startDate, 'DD.MM.YYYY').toDate()
      this.endDate = moment(absence.endDate, 'DD.MM.YYYY').toDate()
    },

    reset() {
      this.$emit('reset')
      this.$refs.rules.reset()
    },

    employeeFilterBy(option, _, search) {
      const displayString = `${option.employeeNumber} - ${option.firstname} ${option.lastname}`

      return (
        displayString.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >
        -1
      )
    },

    validateDates() {
      if (!this.startDate || !this.endDate) {
        return false
      }

      const startDate = moment(this.startDate, 'DD.MM.YYYY')
      const endDate = moment(this.endDate, 'DD.MM.YYYY')

      if (startDate.isAfter(endDate)) {
        return false
      }

      return true
    },

    async submit() {
      const valid = await this.$refs.rules.validate()

      if (!valid) {
        this.$alert.invalid()
        return
      }

      if (!this.validateDates()) {
        this.$alert.error(this.$t('Absences.Error.DateRange'))
        return
      }

      this.$emit('submit')
    },

    changeDate(key, event) {
      this.absence[key] = moment(event).format('DD.MM.YYYY')
      this.dirty = true
    },

    changeDateString(key, event) {
      if (dateRegex.test(event)) {
        this.$set(this, key, moment(event, 'DD.MM.YYYY').toDate())
      }
    },
  },
}
</script>

<style lang="scss">
.timepicker-height {
  > button.btn {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    &[disabled='disabled'] {
      background-color: #efefef !important;
      border-color: #d8d6de !important;
      color: #6e6b7b !important;
    }
  }
}
</style>
