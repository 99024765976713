import moment from 'moment'

import { AbsenceStatus, AbsenceType } from './constants'

/**
 * Factory function to create an absence object.
 * @returns {{
 *   employee: string|null, // string mongo id or null
 *   startDate: string|null, // string YYYY-MM-DD or null
 *   endDate: string|null, // string YYYY-MM-DD or null
 *   type: AbsenceType|null, // enum AbsenceType or null
 *   status: AbsenceStatus, // enum AbsenceStatus
 *   note: string|null, // string or null
 * }}
 */
export const absenceFactory = () => ({
  employee: null,
  startDate: moment().format('DD.MM.YYYY'),
  endDate: moment().endOf('week').format('YYYY-MM-DD'),
  type: AbsenceType.sick,
  note: null,
  status: AbsenceStatus.approved,
})

/**
 * Factory function to create an absence object.
 * @returns {{
 *   employee: string|null, // string mongo id or null
 *   startDate: string, // DD.MM.YYYY
 *   endDate: string, // DD.MM.YYYY
 *   type: AbsenceType, // enum AbsenceType
 *   status: AbsenceStatus, // enum AbsenceStatus
 *   note: string|null, // string or null
 * }}
 */
export const absenceDefaultFactory = () => ({
  employee: null,
  startDate: moment().format('DD.MM.YYYY'),
  endDate: moment().endOf('week').format('DD.MM.YYYY'),
  type: AbsenceType.sick,
  status: AbsenceStatus.approved,
  note: null,
})
