<template>
  <b-overlay :show="isLoading">
    <AbsenceFilter :employees="employees" :filter="filter" />

    <b-card no-body>
      <div class="d-flex custom-search row mb-2 px-2 pt-2 align-items-center">
        <div class="col-md-6">
          <h2 class="m-0 font-weight-bolder">
            {{ $t('Global.All') }} {{ $t('Absences.Absences') }}
          </h2>
        </div>
        <div class="col-md-6 d-md-flex justify-content-end">
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="filter.searchTerm"
              :placeholder="$t('Global.Search')"
              type="text"
              class="d-inline-block search"
            />
            <CreateForm
              v-if="$can($acl.action.Create, $acl.subjects.Absences)"
              :employees="employees"
              :layers="layers"
              @create="createNewAbsence"
            />
          </div>
        </div>
      </div>

      <Table
        :absences="filteredAbsences"
        :search-term="filter.searchTerm"
        :employees="employees"
        @delete="deleteRow"
        @cancel="cancelRow"
        @request-update="absenceToUpdate = $event"
        @view-impact="absenceToViewImpact = $event"
      />

      <UpdateForm
        ref="updateForm"
        :absence="absenceToUpdate"
        :employees="employees"
        :layers="layers"
        @update="updateAbsence"
        @reset="absenceToUpdate = null"
      />

      <ImpactOnShiftModal
        ref="impact-on-shift-modal"
        :absence="absenceToViewImpact"
        :layers="layers"
        :employees="employees"
        @close="absenceToViewImpact = null"
      />
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BFormInput, BCard } from 'bootstrap-vue'

import deleteEntry from '@/utility/scripts/delete'
import cancelEntry from '@/utility/scripts/cancel'

import Table from './components/table.vue'
import CreateForm from './components/create.vue'
import UpdateForm from './components/edit.vue'
import AbsenceFilter from './components/filter.vue'
import ImpactOnShiftModal from './components/impact-on-shift/impact-on-shift-modal.vue'
import { AbsenceStatus } from './utility/constants'

export default {
  name: 'Absences',

  components: {
    BOverlay,
    BFormInput,
    BCard,
    Table,
    CreateForm,
    UpdateForm,
    AbsenceFilter,
    ImpactOnShiftModal,
  },

  data: () => ({
    absences: [],
    employees: [],
    layers: [],

    isLoading: false,

    absenceToUpdate: null,
    absenceToViewImpact: null,

    filter: {
      searchTerm: '',
      employee: null,
      type: null,
      startDate: null,
      endDate: null,
      status: null,
    },
  }),

  computed: {
    filteredAbsences() {
      return this.absences
        .filter(x => {
          if (this.filter.type === null) return true
          return x.type === this.filter.type
        })
        .filter(x => {
          if (this.filter.status === null) return true
          return x.status === this.filter.status
        })
        .filter(x => {
          if (this.filter.employee === null) return true
          return x.employee === this.filter.employee
        })
        .filter(x => {
          if (this.filter.startDate === null) return true
          const date = new Date(x.startDate)

          if (date < this.filter.startDate) return false
          return true
        })
        .filter(x => {
          if (this.filter.endDate === null) return true
          const date = new Date(x.endDate)

          if (date > this.filter.endDate) return false
          return true
        })
    },
  },

  async created() {
    try {
      this.isLoading = true

      const [absences, employees, layers] = await Promise.all([
        this.$axios.get('absences'),
        this.$axios.get('employees'),
        this.$axios.get('layers'),
      ])

      this.employees = employees.data
      this.absences = absences.data
      this.layers = layers.data
    } catch (error) {
      console.error(error)

      this.$alert.error()
    } finally {
      this.isLoading = false
    }
  },

  methods: {
    createNewAbsence(data) {
      this.absences.push(data)
    },

    updateAbsence(updated) {
      const newAbsences = this.absences.map(x => {
        if (x.id === updated.id) {
          return updated
        }

        return x
      })

      newAbsences.sort((a, b) => {
        const startDateComparison =
          new Date(b.startDate) - new Date(a.startDate)

        if (startDateComparison !== 0) {
          return startDateComparison
        }

        // If startDate is the same, sort by endDate in descending order
        return new Date(b.endDate) - new Date(a.endDate)
      })

      this.absences = newAbsences
    },

    async deleteRow(row) {
      const employee = this.employees.find(x => x.id === row.employee)

      await deleteEntry(
        this,
        {
          id: row.id,
          subject: this.$t('Absences.Absence'),
          individual: this.$t('Absences.From', employee),
          endpoint: 'absences/',
          callback: () => {
            this.absences = this.absences.filter(x => x.id !== row.id)
          },
        },
        {
          deleteQuestion: this.$t('Absences.DeleteQuestion', employee),
        },
      )
    },

    async cancelRow(row) {
      const employee = this.employees.find(x => x.id === row.employee)

      await cancelEntry(this, {
        id: row.id,
        subject: this.$t('Absences.Absence'),
        individual: this.$t('Absences.TheFrom', employee),
        endpoint: `absences/${row.id}/cancel`,
        callback: () => {
          this.absences = this.absences.map(x => {
            if (x.id === row.id) {
              return {
                ...x,
                status: AbsenceStatus.canceled,
              }
            }

            return x
          })
        },
      })
    },
  },
}
</script>
