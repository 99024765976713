<template>
  <vue-good-table
    :columns="columns"
    :rows="absences"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm,
    }"
    :pagination-options="{
      enabled: true,
      perPage: pageLength,
    }"
  >
    <div slot="emptystate" class="text-center">
      {{ $t('Global.Emptystate') }}
    </div>
    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field === 'action'" class="action">
        <span v-b-tooltip.hover.top="$t(`Absences.ViewShiftImpact`)">
          <feather-icon
            icon="UsersIcon"
            size="16"
            class="hover-orange"
            @click="$emit('view-impact', props.row)"
          />
        </span>

        <span
          v-if="
            $can($acl.action.Update, $acl.subjects.Absences) &&
            AbsenceStatus.canceled !== props.row.status
          "
          v-b-tooltip.hover.top="
            $t(
              `Global.${
                $can($acl.action.Update, $acl.subjects.Absences)
                  ? 'EditSubject'
                  : 'ViewSubject'
              }`,
              { subject: $t('Absences.Absence') },
            )
          "
        >
          <feather-icon
            :icon="
              $can($acl.action.Update, $acl.subjects.Absences)
                ? 'Edit2Icon'
                : 'EyeIcon'
            "
            size="16"
            class="hover-green"
            @click="$emit('request-update', props.row)"
          />
        </span>

        <span
          v-if="
            $can($acl.action.Delete, $acl.subjects.Absences) &&
            AbsenceStatus.canceled === props.row.status
          "
          v-b-tooltip.hover.top="
            $t('Global.DeleteSubject', {
              subject: $t('Absences.Absence'),
            })
          "
        >
          <feather-icon
            icon="TrashIcon"
            size="16"
            class="hover-red"
            @click="$emit('delete', props.row)"
          />
        </span>

        <span
          v-else
          v-b-tooltip.hover.top="
            $t('Global.CancelSubject', {
              subject: $t('Absences.Absence'),
            })
          "
        >
          <feather-icon
            icon="XCircleIcon"
            size="16"
            class="hover-red"
            @click="$emit('cancel', props.row)"
          />
        </span>
      </span>

      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>

    <!-- pagination -->
    <template slot="pagination-bottom" slot-scope="props">
      <div class="d-flex justify-content-between flex-wrap px-2 pb-2">
        <div class="d-flex align-items-center mb-0 mt-1 flex-wrap">
          <span class="text-nowrap">
            {{ $t('Global.ShowingOneOf') }}
          </span>
          <v-select
            v-model="pageLength"
            class="mx-1"
            style="min-width: 75px"
            :clearable="false"
            :options="['10', '15', '30']"
            @input="value => props.perPageChanged({ currentPerPage: value })"
          />
          <span class="text-nowrap">{{ $t('Global.EntriesShown') }}</span>
        </div>
        <div>
          <b-pagination
            :value="1"
            :total-rows="props.total"
            :per-page="pageLength"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @input="value => props.pageChanged({ currentPage: value })"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </template>
  </vue-good-table>
</template>

<script>
import { BPagination } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import moment from 'moment'

import { AbsenceStatus } from '../utility/constants'

export default {
  name: 'AbsencesTable',

  components: {
    VueGoodTable,
    vSelect,
    BPagination,
  },

  props: {
    absences: {
      type: Array,
      required: true,
    },
    searchTerm: {
      type: String,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      AbsenceStatus,

      pageLength: 10,
      columns: [
        {
          label: this.$t('Absences.EmployeeNumber'),
          field: 'id', // using id as virtual field because if the field is not contained in the object the formatter will not be called
          formatFn: (_, row) => this.getEmployee(row.employee).employeeNumber,
          tdClass: this.rowClass,
        },
        {
          label: this.$t('Absences.Employee'),
          field: 'employee',
          formatFn: value => {
            const employee = this.getEmployee(value)
            return `${employee.firstname} ${employee.lastname}`
          },
          tdClass: this.rowClass,
        },
        {
          label: this.$t('Absences.Type'),
          field: 'type',
          formatFn: value => this.$t(`Absences.AbsenceType.${value}`),
          tdClass: this.rowClass,
        },
        {
          label: this.$t('Absences.StartDate'),
          field: 'startDate',
          formatFn: this.formatDate,
          tdClass: this.rowClass,
        },
        {
          label: this.$t('Absences.EndDate'),
          field: 'endDate',
          formatFn: this.formatDate,
          tdClass: this.rowClass,
        },
        {
          label: this.$t('Absences.Status'),
          field: 'status',
          formatFn: value => this.$t(`Absences.AbsenceStatus.${value}`),
          tdClass: this.rowClass,
        },
        {
          label: this.$t('Absences.CreatedAt'),
          field: 'createdAt',
          formatFn: this.formatDateTime,
          tdClass: this.rowClass,
        },
        {
          label: this.$t('Absences.UpdatedAt'),
          field: 'updatedAt',
          formatFn: this.formatDateTime,
          tdClass: this.rowClass,
        },
        {
          label: this.$t('Global.Action'),
          field: 'action',
          sortable: false,
          tdClass: this.rowClass,
        },
      ],
    }
  },

  computed: {
    getEmployee() {
      const directory = this.employees.reduce((acc, employee) => {
        acc[employee.id] = employee
        return acc
      }, {})

      return id => directory[id]
    },
  },

  methods: {
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY')
    },

    formatDateTime(date) {
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    },

    rowClass(row) {
      if (row.status === AbsenceStatus.canceled) return 'muted'

      return undefined
    },
  },
}
</script>
