export const StaffSchedulingType = {
  Employee: 'employee',
  ServiceProvider: 'serviceProvider',
}

export const StaffSchedulingStatus = {
  open: 'open',
  transmitted: 'transmitted',
  approved: 'approved',

  rejected: 'rejected',
  canceled: 'canceled',
  cancellationNoted: 'cancellationNoted',
}

export const StaffSchedulingEntryType = {
  rejected: 'rejected',
  deleted: 'deleted',
  approved: 'approved',
  canceled: 'canceled',
  cancellationNoted: 'cancellationNoted',
  edited: 'edited',
}

export const StaffSchedulingEntryReason = {
  employeeApproved: 'employeeApproved', // ? employee has approved his shift in the app
  employeeRejected: 'employeeRejected', // ? employee has rejected his shift in the app
  employeeCanceled: 'employeeCanceled', // ? employee has canceled his shift in the app (deprecated)
  employeeCancellationNoted: 'employeeCancellationNoted', // ? employee has noted the cancellation his shift in the app

  employeeNowUnavailable: 'employeeNowUnavailable', // ? employee is now unavailable

  employeeAvailabilityCanceled: 'employeeAvailabilityCanceled', // ? employee canceled the shift because the employee became unavailable
  employeeAvailabilityRejected: 'employeeAvailabilityRejected', // ? employee rejected the shift because the employee became unavailable
  employeeAvailabilityDeleted: 'employeeAvailabilityDeleted', // ? employee deleted the shift because the employee became unavailable

  serviceProviderChangedName: 'serviceProviderChangedName', // ? serviceProvider has changed name of approved employee
  serviceProviderApproved: 'serviceProviderApproved', // ? serviceProvider has approved his shift per link
  serviceProviderApprovedWeek: 'serviceProviderApprovedWeek', // ? serviceProvider has approved entire week per link

  systemInactiveEmployeeDeleted: 'systemInactiveEmployeeDeleted', // ? system deleted the shift because the employee became inactive
  systemInactiveEmployeeCanceled: 'systemInactiveEmployeeCanceled', // ? system canceled the shift because the employee became inactive

  systemInactiveServiceProviderDeleted: 'systemInactiveServiceProviderDeleted', // ? system deleted the shift because the serviceProvider became inactive
  systemInactiveServiceProviderCanceled:
    'systemInactiveServiceProviderCanceled', // ? system canceled the shift because the serviceProvider became inactive

  adminRemovedLayerEmployeeDeleted: 'adminRemovedLayerEmployeeDeleted', // ? shift was deleted because admin removed the layer from an employee
  adminRemovedLayerEmployeeCanceled: 'adminRemovedLayerEmployeeCanceled', // ? shift was canceled because admin removed the layer from an employee

  adminRemovedLayerServiceProviderDeleted:
    'adminRemovedLayerServiceProviderDeleted', // ? shift was deleted because admin removed the layer from an serviceProvider
  adminRemovedLayerServiceProviderCanceled:
    'adminRemovedLayerServiceProviderCanceled', // ? shift was canceled because admin removed the layer from an serviceProvider

  adminServiceProviderCanceled: 'adminServiceProviderCanceled', // ? admin canceled shift
  adminServiceProviderRejected: 'adminServiceProviderRejected', // ? admin rejected shift
}

export const AvailabilityStatus = Object.freeze({
  available: 'available',
  prioritized: 'prioritized',
  unavailable: 'unavailable',
})
